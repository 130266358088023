import { gql } from 'graphql-request';

export const faqsWithCategoryFragment = gql`
  fragment faqsWithCategoryFragment on FaqsWithCategory {
    id
    faqsWithCategory {
      category
      faqs {
        faq {
          question
          answer
        }
      }
    }
  }
`;

export interface FaqsWithCategory {
  __typename: 'FaqsWithCategory';
  id: string;
  faqsWithCategory: {
    category: string;
    defaultOpen?: boolean;
    faqs: {
      faq: {
        question: string;
        answer: string;
      };
    }[];
  }[];
}
